.login {
  .aside-overlay {
    background-color: #00000033;

    h1 {
      font-size: 34px;
    }
    img {
      width: 400px;
      margin: 0 auto;
    }
  }
}
.login-form {
  input {
    color: #000;
    background-color: #e8f0fe !important;
    border-color: #e8f0fe !important;
  }
  .input-group-append {
    padding: 2px !important;
    background-color: #e8f0fe !important;
    border-color: #e8f0fe !important;
    border-top-right-radius: 6px !important;;
    border-bottom-right-radius: 6px !important;;

    span {
      background-color: #e8f0fe !important;
      border-color: #e8f0fe !important;
      border-radius: unset !important;
      border-left: 1px solid #fff !important;
    }
  }
  .svg-icon.svg-icon-primary svg g [fill] {
    fill: #ff6757!important;
  }
}

.modal-close-btn {
  right: 14px !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.20) !important;
}

form label {
  margin-bottom: 0 !important;
}

input:focus, button:focus, text-area:focus {
  outline: none !important;
}

.user-profile-activities {
  .timeline.timeline-6 .timeline-item .timeline-label {
    width: 120px !important;
  }
  .timeline.timeline-6:before {
    left: 121px !important;
  }
}

.profile-summary {
  max-width: 600px;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.20);
  border-radius: 5px;
}

.align-items-unset {
  align-items: unset !important;
}

.apexcharts-legend {
  display: unset !important;
}

.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: block !important;
}

.onboard-tbl th:nth-child(4),
.onboard-tbl td:nth-child(4) {
  border-right: 1px solid #EEF0F8;
}

.custom-form-input {
  fieldset {
    border: 0 !important;
  }
  .MuiInputBase-formControl {
    background-color: #F3F6F9 !important;
  }
}

.MuiPopover-root {
  z-index: 99999 !important;
}
